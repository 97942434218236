import PropTypes from "prop-types";
import React from "react";
import { useMemo } from "react";
import {
  ActionIcon,
  Anchor,
  CopyButton,
  rem,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  Title,
  Text,
  Container,
  Flex,
  Box,
  Image,
  NavLink,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconCheck,
  IconCopy,
  IconTriangleInvertedFilled,
} from "@tabler/icons-react";
import { formatHash } from "../utils/formatHash";
import { useTableIcons } from "../utils/collectionFound/iconsUtil";
import { downloadJsonToCsvMissing } from "../utils/collectionFound/downloadJsonToCsvMissing";
import { downloadJsonToCsvRecords } from "../utils/collectionFound/downloadJsonToCsvRecords";
import {
  useTableData,
  useTableColumns,
  useTableOptions,
  maxTimeStampDiff,
} from "../utils/collectionFound/matchedDataTable";

import {
  useDataToleranceNew,
  useColumnsToleranceNew,
  useTableToleranceNew,
} from "../utils/collectionFound/toleranceDataTableNew";
import CustomTooltip from "./mantineCustomComponets/CustomTooltip";
import { useStylesTooltip } from "../utils/mantine/useStylesTooltip";

const CollectionFound = ({
  contentCollectionFound,
  staticBasePath,
  fileName,
  fileType,
}) => {
  const { classes } = useStylesTooltip();
  // Maximum timestamp deviation from global record
  const resultMaxTimeStampDiff = maxTimeStampDiff(contentCollectionFound);

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Media query to detect if the screen width is less than 64em (1024px)
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  // State for toggling the visibility of the main table
  const [openedTable, handlersTable] = useDisclosure(true);
  const toggleHandlerShowHideTable = () => {
    handlersTable.toggle();
  };

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  // Data preparation for the main table using memoized custom utility function
  const data = useMemo(
    () => useTableData(contentCollectionFound, staticBasePath, fileType),
    [contentCollectionFound.missing_in_csv, staticBasePath, fileType],
  );

  const timeZone = contentCollectionFound?.collection_info?.display_timezone;
  // Column definitions for the main table using a utility function
  const columns = useMemo(() => useTableColumns(fileType, timeZone), []);

  // Configuration options for the main table using Mantine's table library
  const table = useMantineReactTable(
    useTableOptions(columns, data, faIcons, theme),
  );

  // Data preparation for the tolerance table using a custom utility function
  const dataToleranceNew = useMemo(
    () =>
      useDataToleranceNew(
        contentCollectionFound.time_buckets,
        contentCollectionFound.matched_records,
      ),
    [
      contentCollectionFound.time_buckets,
      contentCollectionFound.matched_records,
    ],
  );

  // Column definitions for the tolerance table using a utility function
  const columnsToleranceNew = useMemo(() => useColumnsToleranceNew(theme), []);

  // Configuration options for the tolerance table
  const tableToleranceNew = useMantineReactTable(
    useTableToleranceNew(columnsToleranceNew, dataToleranceNew, theme),
  );

  // Data for csv matched
  const combinedData = contentCollectionFound?.matched_records?.map(
    (record) => ({
      ...record,
      ...contentCollectionFound.collection_info,
    }),
  );

  return (
    <>
      <div className="rb-screen">
        <div className="rb-header-wrap">
          <Container size="sm">
            <Flex justify="center" direction="column" pb={20} pt={20}>
              {contentCollectionFound.missing_in_blockchain.length === 0 &&
              contentCollectionFound.missing_in_csv.length === 0 ? (
                <>
                  <Image
                    src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
                    w={64}
                    alt="Submitted Data Validated!"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <Flex gap="xs" align="center" justify="center">
                    <Title order={1}>Submitted Data Verified!</Title>
                  </Flex>
                  {resultMaxTimeStampDiff && (
                    <>
                      <Flex gap={7.5} align="center" justify="center">
                        <Image
                          w={19}
                          src={`${staticBasePath}public/images/svg/vb-icon-verify.svg`}
                          alt=""
                        />
                        <Text
                          size="xl"
                          classNames={{
                            root: "match-to-blockchain-data",
                          }}
                          styles={(theme) => ({
                            root: {
                              fontFamily: theme.headings.fontFamily.bold,
                            },
                          })}
                        >
                          {" "}
                          100% Match to Blockchain Data
                        </Text>
                      </Flex>

                      {resultMaxTimeStampDiff === "0s" && (
                        <Flex gap={7.5} align="center" justify="center">
                          <Image
                            w={19}
                            src={`${staticBasePath}public/images/svg/vb-icon-verify.svg`}
                            alt=""
                          />
                          <Text
                            size="xl"
                            classNames={{
                              root: "match-to-blockchain-timestamps",
                            }}
                            styles={(theme) => ({
                              root: {
                                fontFamily: theme.headings.fontFamily.bold,
                              },
                            })}
                          >
                            {" "}
                            100% Match to Blockchain Timestamps
                          </Text>
                        </Flex>
                      )}
                      {resultMaxTimeStampDiff !== "0s" && (
                        <Flex gap={7.5} align="center" justify="center">
                          {" "}
                          <Image
                            w={19}
                            src={`${staticBasePath}public/images/svg/info_found.svg`}
                            alt=""
                          />{" "}
                          <Text
                            size="xl"
                            styles={(theme) => ({
                              root: {
                                fontFamily: theme.headings.fontFamily.bold,
                              },
                            })}
                          >
                            Timestamps Match within {resultMaxTimeStampDiff}{" "}
                            <Anchor
                              size="xs"
                              underline="always"
                              c={theme.customColors.blackLight}
                              href="#container-difference"
                            >
                              (Details Below)
                            </Anchor>
                          </Text>
                        </Flex>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Image
                    src={`${staticBasePath}public/images/svg/not_validate.svg`}
                    alt="Archive Cannot be Verified"
                    w={64}
                    className="rb-result-img align-center"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "0",
                    }}
                  />
                  <Flex gap="xs" align="center" justify="center">
                    <Title order={1}>Archive Cannot be Verified</Title>

                    <CustomTooltip
                      content={
                        <>
                          <Text
                            classNames={{
                              root: classes.tooltipText,
                            }}
                          >
                            vBase Verify is unable to verify your Submitted Data
                            vs. the vBase Stamps in the specified Blockchain
                            Collection. Use the details below to find which
                            records/stamps are missing. To read more on how
                            collection verification works,{" "}
                            <Anchor
                              underline="always"
                              c={theme.customColors.blackLight}
                              href="https://docs.vbase.com/"
                              target="_blank"
                            >
                              visit our docs
                            </Anchor>
                            .
                          </Text>
                        </>
                      }
                    ></CustomTooltip>
                  </Flex>
                  {contentCollectionFound?.missing_in_csv.length > 0 && (
                    <Flex gap="xs" pt="xs" align="center" justify="center">
                      <Image
                        src={`${staticBasePath}public/images/svg/red_alert_clamation_icon.svg`}
                        alt="Archive Cannot be Verified"
                        w={20}
                        h={20}
                        className="rb-result-img align-center"
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                      <Text
                        size="lg"
                        classNames={{
                          root: "missing-in-csv",
                        }}
                      >
                        {contentCollectionFound?.missing_in_csv.length} MISSING
                        Record(s) in Submitted Data vs. Blockchain Collection
                      </Text>
                    </Flex>
                  )}

                  {contentCollectionFound?.missing_in_blockchain.length > 0 && (
                    <Flex gap="xs" align="center" justify="center">
                      <Image
                        src={`${staticBasePath}public/images/svg/red_alert_clamation_icon.svg`}
                        alt="Archive Cannot be Verified"
                        w={20}
                        h={20}
                        className="rb-result-img align-center"
                        style={{
                          marginTop: "0",
                          marginBottom: "0",
                        }}
                      />
                      <Text
                        size="lg"
                        classNames={{
                          root: "missing-in-blockchain",
                        }}
                      >
                        {contentCollectionFound?.missing_in_blockchain.length}{" "}
                        EXTRA Record(s) in Submitted Data vs. Blockchain
                        Collection
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Container>
        </div>

        <Container
          styles={() => ({
            root: {
              width: "100%",
            },
          })}
          size="lg"
          pl={0}
          pr={0}
        >
          <Flex
            justify="center"
            gap={20}
            align="stretch"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          >
            <Box
              pb={10}
              w={tabletMediaQuery ? "100%" : "50%"}
              style={{
                backgroundColor: theme.customBackgrounds.white,
              }}
            >
              <Box
                pt={16}
                pl={18}
                pb={10}
                pr={18}
                mb={20}
                style={{
                  borderBottom: "1px solid var(--customBackgrounds-tooltip)",
                }}
              >
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Submitted Data
                  </Title>
                </Flex>
              </Box>
              <Box>
                {contentCollectionFound?.collection_info?.collection_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text title={fileName} size="1em">
                        {formatHash(fileName, 35, 25, 63)}
                      </Text>
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
            <Box
              pt={18}
              pb={18}
              pl={18}
              pr={18}
              ml={tabletMediaQuery ? "auto" : ""}
              mr={tabletMediaQuery ? "auto" : ""}
              style={{
                backgroundColor: theme.customBackgrounds.blackPrimary,
                height: "5.5rem",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Text
                size="1.3em"
                styles={(theme) => ({
                  root: {
                    textAlign: tabletMediaQuery ? "left" : "center",
                    color: theme.customColors.white,
                  },
                })}
              >
                Verified
              </Text>
              <Text
                size="1.3em"
                styles={(theme) => ({
                  root: {
                    textAlign: tabletMediaQuery ? "left" : "center",
                    color: theme.customColors.white,
                  },
                })}
              >
                vs.
              </Text>
            </Box>
            <Box
              w={tabletMediaQuery ? "100%" : "50%"}
              style={{
                backgroundColor: theme.customBackgrounds.white,
              }}
            >
              <Box
                pt={16}
                pl={18}
                pb={10}
                pr={18}
                mb={20}
                style={{
                  borderBottom: "1px solid var(--customBackgrounds-tooltip)",
                }}
              >
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Collection of Blockchain Stamps
                  </Title>
                  <CustomTooltip
                    content={
                      <>
                        <Text
                          classNames={{
                            root: classes.tooltipText,
                          }}
                        >
                          The details below are for the Collection specified in
                          the Submitted Data.
                        </Text>

                        <Text
                          classNames={{
                            root: classes.tooltipText,
                          }}
                        >
                          A Collection is a group of vBase Stamps created by the
                          Collection Owner. One can think of a Collection as a
                          folder or directory that attributes vBase Stamps to
                          specific projects, datasets, strategies, experiments,
                          etc.
                        </Text>

                        <Text
                          classNames={{
                            root: classes.tooltipText,
                          }}
                        >
                          The Collection Name is specified at creation by the
                          Collection Owner. The Collection ID is a fingerprint
                          of the Collection Name. To read more about
                          Collections,{" "}
                          <Anchor
                            underline="always"
                            c={theme.customColors.blackLight}
                            href="https://docs.vbase.com/"
                            target="_blank"
                          >
                            visit our docs
                          </Anchor>
                          .
                        </Text>
                      </>
                    }
                  ></CustomTooltip>
                </Flex>
              </Box>
              <Box>
                {contentCollectionFound?.collection_info?.collection_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text size="1em">Collection Name:</Text>
                      <Text size="1em" className="collection-name">
                        {
                          contentCollectionFound?.collection_info
                            ?.collection_name
                        }
                      </Text>
                    </Flex>
                  </>
                )}

                {contentCollectionFound.collection_info?.collection_id && (
                  <>
                    <Flex
                      pt={0}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                      opacity={0.5}
                    >
                      <Text
                        size="0.88em"
                        styles={(theme) => ({
                          root: {
                            fontFamily: theme.headings.fontFamily.medium,
                          },
                        })}
                      >
                        Collection ID:
                      </Text>
                      <Text size="0.88em">
                        {formatHash(
                          contentCollectionFound.collection_info?.collection_id,
                        )}
                      </Text>
                      <CopyButton
                        value={
                          contentCollectionFound.collection_info?.collection_id
                        }
                        timeout={2000}
                      >
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? "Copied" : "Copy"}
                            withArrow
                            position="right"
                          >
                            <ActionIcon
                              color={copied ? "teal" : "gray"}
                              variant="subtle"
                              onClick={copy}
                            >
                              {copied ? (
                                <IconCheck style={{ width: rem(16) }} />
                              ) : (
                                <IconCopy style={{ width: rem(16) }} />
                              )}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    </Flex>
                  </>
                )}
              </Box>
              <Box pb={20}>
                {contentCollectionFound.collection_info?.user_name && (
                  <>
                    <Flex
                      pt={16}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                    >
                      <Text size="1em">Collection Owner Username:</Text>
                      <Anchor
                        c={theme.customColors.black}
                        underline="always"
                        href={`/verify/user-data/?user=${contentCollectionFound.collection_info?.user_name}`}
                        size="1em"
                        className="collection-user"
                      >
                        {contentCollectionFound.collection_info?.user_name}
                      </Anchor>
                    </Flex>
                  </>
                )}
                {contentCollectionFound.collection_info?.user_address && (
                  <>
                    <Flex
                      pt={0}
                      pl={18}
                      pb={10}
                      pr={18}
                      gap="xs"
                      align="center"
                      opacity={0.5}
                    >
                      <Text size="0.88em">
                        Collection Owner Blockchain Address:
                      </Text>

                      <Anchor
                        c={theme.customColors.black}
                        underline="always"
                        href={`/verify/user-data/?user=${contentCollectionFound.collection_info?.user_address}`}
                        size="0.88em"
                      >
                        {formatHash(
                          contentCollectionFound.collection_info?.user_address,
                        )}
                      </Anchor>

                      <CopyButton
                        value={
                          contentCollectionFound.collection_info?.user_address
                        }
                        timeout={2000}
                      >
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? "Copied" : "Copy"}
                            withArrow
                            position="right"
                          >
                            <ActionIcon
                              color={copied ? "teal" : "gray"}
                              variant="subtle"
                              onClick={copy}
                            >
                              {copied ? (
                                <IconCheck style={{ width: rem(16) }} />
                              ) : (
                                <IconCopy style={{ width: rem(16) }} />
                              )}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
          </Flex>
        </Container>

        {resultMaxTimeStampDiff !== "0s" &&
          contentCollectionFound.missing_in_blockchain.length === 0 &&
          contentCollectionFound.missing_in_csv.length === 0 && (
            <Container
              id="container-difference"
              styles={(theme) => ({
                root: {
                  backgroundColor: theme.customBackgrounds.white,
                  width: "100%",
                  paddingBottom: "1em",
                },
              })}
              size="lg"
              pl={0}
              pr={0}
              mt="lg"
            >
              <Box pt={16} pl={18} pb={15} pr={18}>
                <Flex gap="xs" align="center">
                  <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                    Timestamp Differences
                  </Title>
                  <CustomTooltip
                    content={
                      <>
                        <Text
                          classNames={{
                            root: classes.tooltipText,
                          }}
                          pb={10}
                        >
                          The table below shows how many of the Submitted Data
                          records differed from the blockchain-based timestamps
                          and by how much.
                        </Text>
                        <Text
                          classNames={{
                            root: classes.tooltipText,
                          }}
                        >
                          For record-by-record specifics, see the Record Details
                          section below.
                        </Text>
                      </>
                    }
                  ></CustomTooltip>
                </Flex>
              </Box>
              <Box pl={18} pr={18}>
                <MantineReactTable table={tableToleranceNew} />
              </Box>
              <Text
                pl={18}
                pr={18}
                pt={10}
                pb={10}
                size="1em"
                c={theme.customColors.red}
                styles={(theme) => ({
                  root: {
                    fontFamily: theme.headings.fontFamily.bold,
                  },
                })}
              >
                Maximum Timestamp Deviation: {resultMaxTimeStampDiff}
              </Text>
            </Container>
          )}

        <Container
          styles={(theme) => ({
            root: {
              backgroundColor: theme.customBackgrounds.white,
              width: "100%",
              paddingBottom: "1em",
            },
          })}
          size="lg"
          pl={0}
          pr={0}
          mt="lg"
        >
          <Box pt={16} pl={18} pb={10} pr={18}>
            <Flex gap="xs" align="center" justify="space-between">
              <Title order={2} size="calc(1.25rem * var(--mantine-scale))">
                Record Details
                {contentCollectionFound.missing_in_blockchain.length === 0 &&
                  contentCollectionFound.missing_in_csv.length === 0 && (
                    <> ({contentCollectionFound?.matched_records.length})</>
                  )}
              </Title>

              {contentCollectionFound.missing_in_blockchain.length === 0 &&
                contentCollectionFound.missing_in_csv.length === 0 && (
                  <Flex
                    align="center"
                    justify="space-between"
                    w={tabletMediaQuery ? "auto" : "50%"}
                  >
                    <Anchor
                      underline="always"
                      c={theme.customColors.blackLight}
                      className="download-collection-records"
                      styles={{
                        root: {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() =>
                        downloadJsonToCsvRecords(
                          [
                            ...(Array.isArray(combinedData)
                              ? combinedData
                              : []),
                          ],
                          `CollectionRecords_${fileName}_${formatHash(
                            contentCollectionFound.collection_info
                              ?.collection_id,
                            0,
                            4,
                            21,
                            true,
                          )}.csv`,
                          timeZone,
                          fileType,
                        )
                      }
                    >
                      Download Record Details CSV
                    </Anchor>

                    <NavLink
                      w={100}
                      label={openedTable ? "Hide" : "Show"}
                      onClick={toggleHandlerShowHideTable}
                      opened={openedTable}
                      styles={(theme) => ({
                        root: {
                          backgroundColor: "transparent",
                        },
                        label: {
                          textDecoration: "underline",
                          fontFamily: theme.headings.fontFamily.light,
                          fontSize: "0.875em",
                        },
                      })}
                      leftSection={
                        <Box bg={theme.customBackgrounds.table}>
                          <Flex
                            w={23}
                            h={23}
                            justify="center"
                            align="center"
                            direction="column"
                          >
                            <IconTriangleInvertedFilled
                              style={{
                                transition: "transform 0.3s ease",
                                transform: openedTable
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)",
                              }}
                              size="0.7rem"
                              stroke={1.5}
                            />
                          </Flex>
                        </Box>
                      }
                    />
                  </Flex>
                )}
            </Flex>
          </Box>
          <Box pl={18} pr={18}>
            {(contentCollectionFound?.missing_in_csv.length > 0 ||
              contentCollectionFound?.missing_in_blockchain.length > 0) && (
              <Box pb={15}>
                <Anchor
                  underline="always"
                  c={theme.customColors.blackLight}
                  className="download-archive-records"
                  styles={{
                    root: {
                      cursor: "pointer",
                      paddingBottom: "1.75em",
                    },
                  }}
                  onClick={() =>
                    downloadJsonToCsvMissing(
                      contentCollectionFound.original_csv_records,
                      true,
                      "archive_records.csv",
                    )
                  }
                >
                  Download Archive records
                </Anchor>
              </Box>
            )}

            {(contentCollectionFound?.missing_in_csv.length > 0 ||
              contentCollectionFound?.missing_in_blockchain.length > 0) && (
              <Box>
                <Anchor
                  underline="always"
                  c={theme.customColors.blackLight}
                  className="download-collection-records"
                  styles={{
                    root: {
                      cursor: "pointer",
                      paddingBottom: "1.75em",
                    },
                  }}
                  onClick={() =>
                    downloadJsonToCsvMissing(
                      contentCollectionFound.original_commitment_receipts,
                      false,
                      "collection_records.csv",
                    )
                  }
                >
                  Download Collection records
                </Anchor>
              </Box>
            )}

            {contentCollectionFound?.matched_records.length > 0 &&
              contentCollectionFound?.missing_in_csv.length === 0 &&
              contentCollectionFound?.missing_in_blockchain.length === 0 && (
                <>
                  {openedTable && <MantineReactTable mb="lg" table={table} />}
                </>
              )}
          </Box>
        </Container>
      </div>
    </>
  );
};

CollectionFound.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  contentCollectionFound: PropTypes.object,
  fileType: PropTypes.string.isRequired,
};
export default CollectionFound;
