import React from "react";
import {
  ActionIcon,
  CopyButton,
  Flex,
  rem,
  Text,
  Tooltip,
} from "@mantine/core";
import { formatTimeStamp, LONG_DISPLAY_FORMAT } from "./dateTimeUtils";
import { formatHash } from "../formatHash";
import LogoBlockchainSvg from "../../svg/LogoBlockchainSvg";
import LogoVBSvg from "../../svg/LogoVBSvg";
import DownloadPDFUserFound from "../../components/DownloadPDFUserFound";
import {
  calculateTimeDifference,
  parseTimeDiff,
  parseDate,
  calculateTimeDifferenceInSeconds,
  formatTimeDifferenceFromSeconds,
} from "./resultScreen";
import { IconCheck, IconCopy } from "@tabler/icons-react";

// Function for creating data
export const useTableData = (
  contentCollectionFound,
  staticBasePath,
  fileType,
) => {
  if (!Array.isArray(contentCollectionFound?.matched_records)) {
    return [];
  }
  const timeZone = contentCollectionFound.collection_info?.display_timezone;
  const newMatchedRecords = contentCollectionFound.matched_records.map(
    (item) => ({
      ...(fileType === "application/zip" && {
        csv_label: (
          <Text
            styles={() => ({
              root: {
                wordBreak: "break-all",
              },
            })}
          >
            {item.csv_label}
          </Text>
        ),
      }),
      timestamp: formatTimeStamp(item.timestamp, timeZone, LONG_DISPLAY_FORMAT),
      csv_timestamp: formatTimeStamp(
        item.csv_timestamp,
        timeZone,
        LONG_DISPLAY_FORMAT,
      ),
      time_stamp_diff: calculateTimeDifference(
        item.timestamp,
        item.csv_timestamp,
      ),
      objectCid: (
        <Flex align="center" gap={8}>
          {formatHash(item.objectCid, 6, 4, 13)}

          <CopyButton value={item.objectCid} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      ),
      blockchain_receipts: (
        <Flex align="center" justify="center" gap={10}>
          {item?.objectCid && (
            <a
              title="vBase Stamp Verification"
              href={`/verify/?cid=${item?.objectCid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoVBSvg />
            </a>
          )}
          {item?.transactionHash && (
            <a
              title="Public Blockchain Record"
              href={`${item.blockExplorerUrl}${item?.transactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoBlockchainSvg />
            </a>
          )}
          <DownloadPDFUserFound
            user_id={item.user_id}
            user={item.user}
            collection_name={
              contentCollectionFound.collection_info.collection_name
            }
            collection_hash={
              contentCollectionFound.collection_info.collection_id
            }
            objectCid={item.objectCid}
            blockExplorerUrl={item.blockExplorerUrl}
            transactionHash={item.transactionHash}
            chainId={item.chainId}
            blockchainName={item.blockchainName}
            formattedDateTime={
              formatTimeStamp(item.timestamp, timeZone, LONG_DISPLAY_FORMAT) +
              " " +
              timeZone
            }
          />
        </Flex>
      ),
    }),
  );

  return newMatchedRecords;
};

export const maxTimeStampDiff = (contentCollectionFound) => {
  if (!Array.isArray(contentCollectionFound?.matched_records)) {
    return 0;
  }

  const newMatchedRecords = contentCollectionFound.matched_records.map(
    (item) => ({
      time_stamp_diff_seconds: calculateTimeDifferenceInSeconds(
        item.timestamp,
        item.csv_timestamp,
      ),
    }),
  );

  const maxTimeStampDiffSeconds = newMatchedRecords.reduce(
    (max, record) => Math.max(max, record.time_stamp_diff_seconds),
    0,
  );
  return formatTimeDifferenceFromSeconds(maxTimeStampDiffSeconds);
};

// Function for creating columns
export const useTableColumns = (fileType, timeZone) => [
  ...(fileType === "application/zip"
    ? [
        {
          accessorKey: "csv_label",
          header: "File Name",
          enableSorting: true,
          size: 235,
          sortingFn: (rowA, rowB) => {
            const labelA = rowA.original.csv_label.props?.children || "";
            const labelB = rowB.original.csv_label.props?.children || "";
            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
          },
        },
      ]
    : []),
  {
    accessorKey: "objectCid",
    header: "Content ID",
    enableSorting: true,
    size: 135,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.objectCid.props?.children?.[0] || "";
      const cidB = rowB.original.objectCid.props?.children?.[0] || "";
      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "timestamp",
    header: (
      <>
        Blockchain Timestamp
        <br />
        (Timezone:{timeZone})
      </>
    ),
    size: 200,
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.timestamp);
      const timestampB = parseDate(rowB.original.timestamp);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "csv_timestamp",
    header: (
      <>
        Archive Timestamp
        <br />
        (Timezone:{timeZone})
      </>
    ),
    size: 200,
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.csv_timestamp);
      const timestampB = parseDate(rowB.original.csv_timestamp);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "time_stamp_diff",
    header: "Timestamp Diff vs Archive",
    size: 140,
    sortingFn: (rowA, rowB) => {
      const diffA = parseTimeDiff(rowA.original.time_stamp_diff);
      const diffB = parseTimeDiff(rowB.original.time_stamp_diff);
      return diffA - diffB;
    },
  },
  {
    accessorKey: "blockchain_receipts",
    header: "Blockchain Receipts",
    enableSorting: false,
    size: 80,
    mantineTableBodyCellProps: {
      align: "center",
    },
  },
];

// Function for creating icons

// Function for configuring the table
export const useTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 20 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});
