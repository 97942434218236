import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { URLs } from "../../utils/apis";
import {
  Box,
  CopyButton,
  Button,
  Flex,
  PasswordInput,
  Select,
  Tooltip,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";
import moment from "moment-timezone";
import CustomButton from "../mantineCustomComponets/CustomButton";
import { changeTimezone } from "../../utils/userProfile";
import { IconQuestionMark } from "@tabler/icons-react";

const allTimezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).utcOffset();
  const currentTime = moment.tz(timezone).format("h:mmA");
  const label = ` ${timezone.padEnd(5)}   -   ${currentTime.padStart(5)}`;
  return {
    value: timezone,
    label,
    offset,
  };
});

const priorityTimezones = [
  "UTC",
  "US/Eastern",
  "US/Central",
  "US/Mountain",
  "US/Pacific",
  "Asia/Shanghai",
  "Asia/Kolkata",
  "Europe/London",
  "Europe/Paris",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
];

const sortedTimezones = allTimezones.sort((a, b) => {
  const indexA = priorityTimezones.indexOf(a.value);
  const indexB = priorityTimezones.indexOf(b.value);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;

  return a.value.localeCompare(b.value);
});

const AccountSettings = ({ onTimeZone, timeZone }) => {
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  const [openedTooltip, setOpenedTooltip] = useState(false);

  let closeTimeoutTooltip;

  const handleMouseEnterTooltip = () => {
    clearTimeout(closeTimeoutTooltip);
    setOpenedTooltip(true);
  };

  const handleMouseLeaveTooltip = () => {
    closeTimeoutTooltip = setTimeout(() => {
      setOpenedTooltip(false);
    }, 500);
  };

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    api_key: "",
    private_key: "",
  });

  // Fetch user data from API
  useEffect(() => {
    const fetchQuotasData = async () => {
      try {
        const response = await fetch(URLs.PROFILE_API_KEYS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setInputValues({
          api_key: data.api_key || "",
          private_key: data.private_key || "",
        });
        onTimeZone(data.display_timezone);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchQuotasData();
  }, []); // Empty dependency array means this effect runs once on mount

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }

  return (
    <Box
      className="billing"
      style={() => ({
        paddingTop: 83,
        paddingBottom: 100,
      })}
    >
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          styles={(theme) => ({
            root: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: tabletMediaQuery ? "flex-start" : "center",
            },
            label: {
              paddingRight: 20,
              fontFamily: theme.headings.fontFamily.bold,
              fontSize: "1.2em",
              width: 236,
              textAlign: tabletMediaQuery ? "left" : "right",
            },
            input: {
              paddingRight: 20,
              border: "1px solid rgba(112, 112, 112, 0.5019607843)",
              fontSize: "1rem",

              lineHeight: "1.25rem",
              maxWidth: "100%",
              width: tabletMediaQuery ? "100%" : "22rem",
            },
            wrapper: {
              width: tabletMediaQuery ? "100%" : "auto",
            },
          })}
          label="API Key:"
          readOnly
          value={inputValues["api_key"]}
        />
        <CopyButton value={inputValues["api_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  textDecoration: "underline",
                  paddingLeft: "0",

                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          styles={(theme) => ({
            root: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: tabletMediaQuery ? "flex-start" : "center",
            },
            label: {
              paddingRight: 20,
              fontFamily: theme.headings.fontFamily.bold,
              fontSize: "1.2em",
              width: 236,
              textAlign: tabletMediaQuery ? "left" : "right",
            },
            input: {
              paddingRight: 20,
              border: "1px solid rgba(112, 112, 112, 0.5019607843)",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              maxWidth: "100%",
              width: tabletMediaQuery ? "100%" : "22rem",
            },
            wrapper: {
              width: tabletMediaQuery ? "100%" : "auto",
            },
          })}
          label="Private Key:"
          readOnly
          value={inputValues["private_key"]}
        />
        <CopyButton value={inputValues["private_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  textDecoration: "underline",
                  paddingLeft: "0",

                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>

      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <Select
          label={
            <Flex align="center" gap="8">
              Display Timezone
              <Tooltip
                multiline
                w={220}
                withArrow
                transitionProps={{ transition: "fade-up", duration: 200 }}
                styles={(theme) => ({
                  tooltip: {
                    color: theme.customColors.black,
                    backgroundColor: theme.customBackgrounds.tooltip,
                    pointerEvents: "auto",
                  },
                })}
                opened={openedTooltip}
                label={
                  <Text
                    onMouseEnter={handleMouseEnterTooltip}
                    onMouseLeave={handleMouseLeaveTooltip}
                  >
                    Specify the timezone that will be used to display timestamps
                    for you across the vBase application. You can select
                    timezones tied to locations (e.g. New York) or tied to
                    universal time, e.g. (GMT-5)
                  </Text>
                }
              >
                <Button
                  onMouseEnter={handleMouseEnterTooltip}
                  onMouseLeave={handleMouseLeaveTooltip}
                  styles={(theme) => ({
                    root: {
                      border: `1px solid ${theme.customColors.blackLight}`,
                      width: "1.125rem",
                      height: "1.125rem",
                      padding: "0",
                      backgroundColor: theme.customBackgrounds.tooltip,
                    },
                  })}
                  size="compact-xs"
                  radius="50%"
                >
                  <IconQuestionMark
                    style={{
                      width: rem(12),
                      height: rem(12),
                      stroke: theme.customBackgrounds.black,
                    }}
                  />
                </Button>
              </Tooltip>
            </Flex>
          }
          styles={(theme) => ({
            root: {
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: tabletMediaQuery ? "flex-start" : "center",
              width: tabletMediaQuery ? "17rem" : "",
            },
            label: {
              paddingRight: 20,
              fontFamily: theme.headings.fontFamily.bold,
              fontSize: "1.2em",
              width: 236,
              textAlign: tabletMediaQuery ? "left" : "right",
              display: "flex",
              justifyContent: tabletMediaQuery ? "flex-start" : "flex-end",
            },
            input: {
              paddingRight: 20,
              border: "1px solid rgba(112, 112, 112, 0.5019607843)",
              fontSize: "1rem",
              lineHeight: "1.25rem",
              maxWidth: "100%",
              width: tabletMediaQuery ? "100%" : "22rem",
            },
            wrapper: {
              width: tabletMediaQuery ? "100%" : "auto",
            },
          })}
          data={sortedTimezones}
          value={timeZone}
          onChange={onTimeZone}
          clearable
          searchable
        />
        <CustomButton
          text="Save"
          componentClasses="link"
          type="button"
          variant="transparent"
          onClick={() => {
            changeTimezone(timeZone);
          }}
        />
      </Flex>
    </Box>
  );
};

AccountSettings.propTypes = {
  onTimeZone: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
};

export default AccountSettings;
