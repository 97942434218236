import { createStyles } from "@mantine/emotion";
import { rem } from "@mantine/core";

export const useStylesButton = createStyles((theme) => ({
  buttonBlack: {
    backgroundColor: theme.customColors.black,
    color: theme.customColors.white,
    fontSize: "1.2em",
    border: "1px solid " + theme.customColors.black,
    "&:hover": {
      backgroundColor: theme.customColors.white,
      color: theme.customColors.black,
    },
  },

  buttonWhite: {
    backgroundColor: theme.customColors.white,
    color: theme.customColors.black,
    fontSize: "1.2em",
    border: "1px solid " + theme.customColors.black,
    "&:hover": {
      backgroundColor: theme.customColors.black,
      color: theme.customColors.white,
    },
  },
  link: {
    fontSize: "1em",
    textDecoration: "underline",
    paddingLeft: "0",
    width: "auto",
    textAlign: "left",
    color: theme.customColors.blackLight,
    "&:hover": {
      textDecoration: "none",
      color: theme.customColors.blackLight,
    },
  },
  linkWidth90: {
    width: 90,
  },
  iconChevronLeft: {
    width: rem(21),
    height: rem(33),
    stroke: theme.customColors.white,
  },
  anchor: {
    borderRadius: "0.3em",
    padding: "0.5em 1em",
    "&:hover svg": {
      stroke: theme.customColors.black,
    },
  },
}));
