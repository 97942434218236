import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { formatCollectionUserFound } from "../utils/formatCollectionUserFound";
import { formatHash } from "../utils/formatHash";

const FrontendPagination = ({
  items,
  itemsPerPage,
  onFilteredItemsChange,
  onFilteredItemsNoPaginationChange,
  searchTerm,
  onCurrentPage,
  currentPage,
}) => {
  // Filtered items based on the search term
  const filteredItems = useMemo(
    () =>
      Array.isArray(items) && items.length > 0
        ? items.filter((item) => {
            const nameOrHash =
              item.collection_name && item.collection_name !== "n/a"
                ? item.collection_name
                : item.collection_hash && item.collection_hash !== "n/a"
                ? formatHash(item.collection_hash, 6, 4, 13)
                : "<<None>>";

            return (
              nameOrHash &&
              typeof nameOrHash === "string" &&
              formatCollectionUserFound(nameOrHash)
                .formatted.toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
          })
        : [],
    [items, searchTerm],
  );

  useEffect(() => {
    onFilteredItemsNoPaginationChange(filteredItems);
  }, [filteredItems, onFilteredItemsNoPaginationChange]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Get the items for the current page
  const currentItems = useMemo(
    () =>
      filteredItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      ),
    [filteredItems, currentPage, itemsPerPage],
  );

  useEffect(() => {
    onFilteredItemsChange(currentItems);
  }, [currentItems, onFilteredItemsChange]);

  // Generate the page numbers for navigation
  const delta = 1;
  const generatePageNumbers = () => {
    const pageNumbers = [];
    const left = Math.max(2, currentPage - delta);
    const right = Math.min(totalPages - 1, currentPage + delta);

    // Always include the first page
    pageNumbers.push(1);

    // Add an ellipsis if there's a gap between the first and left boundary
    if (left > 2) {
      pageNumbers.push("...");
    }

    // Add pages around the current page
    for (let i = left; i <= right; i++) {
      pageNumbers.push(i);
    }

    // Add an ellipsis if there's a gap between the right boundary and last page
    if (right < totalPages - 1) {
      pageNumbers.push("...");
    }

    // Always include the last page
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      {filteredItems.length > itemsPerPage && (
        <div className="pagination-controls rb-display-flex rb-justify-content-center">
          {/* Button to navigate to the first page */}
          <button
            className={`pagination-controls-item pagination-controls-first ${
              currentPage === 1 ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(1)}
            disabled={currentPage === 1}
          >
            First
          </button>

          {/* Button to navigate to the previous page */}
          <button
            className={`pagination-controls-item pagination-controls-previous ${
              currentPage === 1 ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {/* Generate and render page numbers */}
          {generatePageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && onCurrentPage(page)}
              disabled={page === currentPage || page === "..."}
              className={`pagination-controls-item ${
                page === currentPage ? "pagination-controls-active" : ""
              }`}
            >
              {page}
            </button>
          ))}

          {/* Button to navigate to the next page */}
          <button
            className={`pagination-controls-item pagination-controls-next ${
              currentPage === totalPages ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>

          {/* Button to navigate to the last page */}
          <button
            className={`pagination-controls-item pagination-controls-last ${
              currentPage === totalPages ? "pagination-controls-disabled" : ""
            }`}
            onClick={() => onCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </div>
      )}
    </div>
  );
};
FrontendPagination.propTypes = {
  items: PropTypes.array.isRequired, // Array type for items
  itemsPerPage: PropTypes.number.isRequired, // Number type for itemsPerPage
  onFilteredItemsChange: PropTypes.func.isRequired, // Function type for onFilteredItemsChange
  onFilteredItemsNoPaginationChange: PropTypes.func.isRequired, // Function type for onFilteredItemsNoPaginationChange
  searchTerm: PropTypes.string.isRequired, // String type for searchTerm
  onCurrentPage: PropTypes.func.isRequired, // Function type for onCurrentPage
  currentPage: PropTypes.number.isRequired, // Number type for currentPage
};
export default FrontendPagination;
