import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { createSubmit } from "../utils/createSubmit";
import { formatHash } from "../utils/formatHash";
const SelectPopUpCollection = ({
  onSelectPopUpShow,
  selectedOptionCollection,
  objectHash,
  setLoading,
  setError,
  handleVerifyHashWorker,
  handleSelectedOptionCollection,
}) => {
  const handleConfirmCollection = () => {
    createSubmit(
      objectHash,
      setLoading,
      setError,
      handleVerifyHashWorker,
      selectedOptionCollection,
      handleSelectedOptionCollection,
    );
    onSelectPopUpShow(false);
  };
  const handleClosePopUpCollection = () => {
    onSelectPopUpShow(false);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to update `isMobile` state based on screen width
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for screen resizing
    window.addEventListener("resize", updateScreenSize);

    // Set the initial value for `isMobile`
    updateScreenSize();

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);
  return (
    <div className="rb-loading-wrap rb-confirmation-collection-wrap">
      <div className="rb-loading__title">Confirm Stamp Creation</div>

      <div className="rb-loading__text rb-confirmation-collection__text">
        <b>Collection Name:</b>{" "}
        {selectedOptionCollection?.label
          ? selectedOptionCollection?.label
          : selectedOptionCollection?.value
          ? "<<Not Available>>"
          : "<<None>>"}
      </div>
      <div className="rb-loading__text rb-confirmation-collection__text">
        {isMobile ? (
          <>
            <b>Content ID (SHA3-256):</b> {formatHash(objectHash)}
          </>
        ) : (
          <>
            <b>Content ID (SHA3-256):</b> {objectHash}
          </>
        )}
      </div>

      <div className="rb-loading__title rb-confirmation__title">Proceed?</div>
      <div className="rb-confirmation__buttons rb-display-flex rb-justify-content-space-between">
        <button
          className="button rb-btn rb-btn-black rb-display-flex rb-align-items-center"
          onClick={() => handleConfirmCollection()}
        >
          Yes
        </button>
        <button
          className="button rb-btn rb-btn-white rb-display-flex rb-align-items-center"
          onClick={() => handleClosePopUpCollection()}
        >
          No
        </button>
      </div>
    </div>
  );
};

SelectPopUpCollection.propTypes = {
  selectedOptionCollection: PropTypes.object.isRequired,
  onSelectPopUpShow: PropTypes.func.isRequired,
  objectHash: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  handleVerifyHashWorker: PropTypes.func.isRequired,
  handleSelectedOptionCollection: PropTypes.func.isRequired,
};

export default SelectPopUpCollection;
