import PropTypes from "prop-types";
import React from "react";
import { Button } from "@mantine/core";
import { useStylesButton } from "../../utils/mantine/useStylesButton";

const CustomButton = ({
  text,
  componentClasses,
  type = "submit",
  variant = "filled",
  onClick = "",
  loading = false,
}) => {
  const { classes } = useStylesButton();
  const classList = componentClasses.split(" ");

  const combinedClasses = classList
    .map((className) => {
      return classes[className] || className;
    })
    .filter(Boolean);
  return (
    <Button
      className={combinedClasses.join(" ")}
      type={type}
      variant={variant}
      loading={loading}
      onClick={onClick || (() => {})}
    >
      {text}
    </Button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  componentClasses: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  variant: PropTypes.oneOf(["filled", "outline", "transparent"]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default CustomButton;
