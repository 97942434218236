import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { handleClickToCopy } from "../utils/copyToClipboard";
import { paddleDataSuccess, URLs } from "../utils/apis";
import { formatHash } from "../utils/formatHash";
const defaultInputValues = {
  subscription_info: {
    subscription_paddle_id: false,
    last_transaction_paddle_id: false,
  },
  user_balance_info: {
    expirable_credits: 0,
    paid_credits: 0,
    total_credits: 0,
  },
  subscription_plan: {
    title: false,
    description: false,
    total_quota_limit: 0,
  },
  billing_info: {
    email: "",
  },
};

const SubscriptionSuccess = ({ staticBasePath }) => {
  const [inputValues, setInputValues] = useState(defaultInputValues);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    paddleDataSuccess(setInputValues, setIsLoading);
  }, []);

  if (isLoading) {
    return (
      <div className="rb-display-flex rb-justify-content-center">
        Loading...
      </div>
    ); // Show a loading message while data is being fetched
  }

  return (
    <>
      <div className="rb-main-content rb-subscription-success">
        <img
          src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
          alt="Green checkmark"
          className="rb-result-img align-center"
        />
        <h2 className="rb-header rb-header-stamp rb-text-center">
          Congratulations!
        </h2>
        <p>Your payment has been successfully confirmed.</p>
        <p>
          This receipt has been e-mailed to{" "}
          <b>{inputValues.billing_info.email}</b> e-mail
        </p>
        <div className="rb-subscription-success-buttons rb-display-flex rb-justify-content-space-between">
          <a href={URLs.USER_BILLING_URL} className="rb-btn rb-btn-black">
            Profile
          </a>
          <a href={URLs.STAMP_URL} className="rb-btn rb-btn-white">
            Stamp
          </a>
        </div>
        <div className="rb-container rb-align-center">
          <div className="rb-details-table">
            <div className="rb-details-table__header">
              <span>Subscription details</span>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Subscription Name
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.subscription_plan.title}
              </div>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Subscription ID
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {formatHash(
                  inputValues.subscription_info.subscription_paddle_id,
                )}
                <img
                  className="rb-details-table__copy"
                  src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                  alt="copy"
                  onClick={() =>
                    handleClickToCopy(
                      inputValues.subscription_info.subscription_paddle_id,
                    )
                  }
                />
              </div>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Transaction ID
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.subscription_info.last_transaction_paddle_id}
                <img
                  className="rb-details-table__copy"
                  src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                  alt="copy"
                  onClick={() =>
                    handleClickToCopy(
                      inputValues.subscription_info.last_transaction_paddle_id,
                    )
                  }
                />
              </div>
            </div>

            <div className="rb-details-table__header">
              <span>Your balance</span>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Newly purchased credits:
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.subscription_plan.total_quota_limit - 100}
              </div>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Previously Purchased Credits:
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.user_balance_info.paid_credits -
                  inputValues.subscription_plan.total_quota_limit +
                  100}
              </div>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Monthly Free Credits Remaining:
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.user_balance_info.expirable_credits}
              </div>
            </div>

            <div className="rb-details-table__item">
              <div className="rb-details-table__item__header">
                Total balance:
              </div>
              <div className="rb-details-table__dotted-space">
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div
                id="stamp-found__account_id"
                className="rb-details-table__item__value"
              >
                {inputValues.user_balance_info.total_credits}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SubscriptionSuccess.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
};
export default SubscriptionSuccess;
