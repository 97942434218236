import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { fetchStatisticsData } from "../../utils/apis";
import { Box, Flex, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  formatTimeStamp,
  SHORT_DISPLAY_FORMAT,
} from "../../utils/collectionFound/dateTimeUtils";

const UsageStatistics = ({ timeZone }) => {
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    last_login: "",
    date_of_account_created: "",
  });

  // Fetch user data from API
  useEffect(() => {
    fetchStatisticsData(setInputValues, setIsLoading);
  }, []); // Empty dependency array means this effect runs once on mount

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }
  return (
    <Box
      className="billing"
      style={() => ({
        paddingTop: 83,
        paddingBottom: 100,
      })}
    >
      {inputValues["last_login"] && (
        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            disabled
            styles={(theme) => ({
              root: {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Date of Last Login:"
            value={formatTimeStamp(
              inputValues["last_login"],
              timeZone,
              SHORT_DISPLAY_FORMAT,
            )}
          />
        </Flex>
      )}
      {inputValues["user_joined"] && (
        <Flex
          gap={20}
          mb={32}
          align="center"
          wrap={tabletMediaQuery ? "wrap" : "nowrap"}
          justify={tabletMediaQuery ? "flex-start" : "center"}
        >
          <TextInput
            disabled
            styles={(theme) => ({
              root: {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: tabletMediaQuery ? "flex-start" : "center",
              },
              label: {
                paddingRight: 20,
                fontFamily: theme.headings.fontFamily.bold,
                fontSize: "1.2em",
                width: 236,
                textAlign: tabletMediaQuery ? "left" : "right",
              },
              input: {
                paddingRight: 20,
                border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                maxWidth: "100%",
                width: tabletMediaQuery ? "100%" : "22rem",
              },
            })}
            label="Date of Account Created:"
            value={formatTimeStamp(
              inputValues["user_joined"],
              timeZone,
              SHORT_DISPLAY_FORMAT,
            )}
          />
        </Flex>
      )}
    </Box>
  );
};

UsageStatistics.propTypes = {
  timeZone: PropTypes.string.isRequired,
};

export default UsageStatistics;
