import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";

import { Tabs, Select } from "@mantine/core";

import "@mantine/core/styles.css";

import LendingPricingSvg from "../svg/LendingPricingSvg";
import { userFoundData } from "../utils/apis";
import ReportBlock from "./reports/ReportBlock";
import { handleClickToCopy } from "../utils/copyToClipboard";

import LogoVBSvg from "../svg/LogoVBSvg";
import LogoBlockchainSvg from "../svg/LogoBlockchainSvg";

import { URLs } from "../utils/apis";

import FrontendPagination from "./FrontendPagination";

//select icon
import { IconCaretDownFilled } from "@tabler/icons-react";

import LoadingUserFound from "./LoadingUserFound";
import { formatCollectionUserFound } from "../utils/formatCollectionUserFound";

import {
  formatTimeStamp,
  SHORT_DISPLAY_FORMAT,
  LONG_DISPLAY_FORMAT,
} from "../utils/collectionFound/dateTimeUtils";

import DownloadPDFUserFound from "./DownloadPDFUserFound";
import FrontendPaginationCollections from "./FrontendPaginationCollections";
import DownloadCSV from "./DownloadCSV";
import { formatHash } from "../utils/formatHash";

const optionsFilter = ({ options, search }) => {
  const searchLower = search.toLowerCase().trim();
  return options.filter((option) =>
    option.value.toLowerCase().includes(searchLower),
  );
};

const UserFoundContent = ({ staticBasePath }) => {
  const selectIcon = (
    <IconCaretDownFilled width={12} color="rgba(112,112,112,0.4)" />
  );

  // fetch all user found data loaded
  const [dataLoadedUserFound, setDataLoadedUserFound] = useState(false);

  useEffect(() => {
    if (Array.isArray(inputValues?.collection_data)) {
      const selectOptionsData = inputValues.collection_data
        .filter(
          (item) =>
            (item.collection_name &&
              typeof item.collection_name === "string") ||
            (item.collection_hash && typeof item.collection_hash === "string"),
        )
        .map((item) => {
          const collectionName =
            item.collection_name === "n/a" && item.collection_hash === "n/a"
              ? "<<None>>"
              : item.collection_name === "n/a" && item.collection_hash !== "n/a"
              ? "<<Not Available>>"
              : item.collection_name;
          const collectionHash =
            item.collection_hash === "n/a" ? "<<None>>" : item.collection_hash;

          const label = collectionName
            ? formatCollectionUserFound(collectionName).formatted
            : formatHash(collectionHash, 6, 4, 13);

          return {
            label: label,
            value: label,
          };
        });

      setSelectOptions(selectOptionsData);
    }
  }, [inputValues?.collection_data, dataLoadedUserFound]);

  const [selectOptions, setSelectOptions] = useState([]);

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    collection_data: [],
    commitment_receipts_data: [],
    user_info_data: {},
    user_found: false,
    user_logged_in: true,
  });

  const [collectionExists, setCollectionExists] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const user = params.get("user");

    const collectionName = params.get("collection_name");
    const collectionCid = params.get("collection_cid");
    if (collectionName || collectionCid) {
      setCollectionExists(true);
    }
    const fetchUserFoundData = async () => {
      await userFoundData(
        setInputValues,
        user,
        setLoading,
        collectionName,
        collectionCid,
      );
      setDataLoadedUserFound(true);
    };

    fetchUserFoundData();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectChange = (value) => {
    if (value === null) {
      setSearchTerm("");
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (value) => {
    if (value === null) {
      setSearchTerm("");
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1);
  };

  const [filteredItems, setFilteredItems] = useState([]);

  const handleFilteredItemsChange = useCallback((items) => {
    setFilteredItems(items);
  }, []);

  const [filteredItemsNoPagination, setFilteredItemsNoPagination] = useState(
    [],
  );

  const handleFilteredItemsNoPaginationChange = useCallback((items) => {
    setFilteredItemsNoPagination(items);
  }, []);

  //Collections
  const [filteredItemsCollections, setFilteredItemsCollections] = useState([]);

  const handleFilteredItemsCollectionsChange = useCallback((items) => {
    setFilteredItemsCollections(items);
  }, []);

  const handleClickFilterredbyCollection = (collection_name) => {
    setActiveTab("stamp_history");
    setSearchTerm(collection_name);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handleCurrentPage = useCallback((currentPage) => {
    setCurrentPage(currentPage);
  }, []);

  // Initialize the activeTab state from the URL hash
  const [activeTab, setActiveTab] = useState(() => {
    // Extract the hash from the URL (without the '#' symbol)
    const hash = window.location.hash.slice(1);
    return hash || "stamp_history"; // Default to 'Stamp History' tab if no hash is present
  });

  useEffect(() => {
    // Handler for hash change events
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setCurrentPage(1);
        setActiveTab(hash); // Update the active tab based on the hash
      }
    };

    // Listen for changes to the URL hash
    window.addEventListener("hashchange", handleHashChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Function to handle tab change
  const handleTabChange = (value) => {
    setCurrentPage(1);
    setActiveTab(value); // Update the active tab
    window.location.hash = value; // Update the URL hash when the tab changes
  };

  const [loading, setLoading] = useState(true);
  const userTimezone = inputValues?.user_info_data?.display_timezone;

  return (
    <div className="reports reports-producer user-found">
      {loading && <LoadingUserFound />}
      <div className="reports-text rb-text-center">
        {inputValues?.user_info_data?.user_address &&
        inputValues?.collection_data.length > 0 ? (
          <img
            src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
            alt="Green checkmark"
            className="rb-result-img align-center"
          />
        ) : (
          <img
            src={`${staticBasePath}public/images/svg/vb-icon-not-verified.svg`}
            alt="Red checkmark"
            className="rb-result-img align-center"
          />
        )}

        <h1>
          {inputValues?.user_info_data?.user_address
            ? collectionExists
              ? inputValues?.collection_data.length > 0
                ? "Collection Detail"
                : "Collection Not Found"
              : "User Found!"
            : "User Not Found!"}
        </h1>
      </div>
      {inputValues?.user_info_data?.user_address ? (
        <div className="reports-content">
          <div className="reports-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
            <div className="reports-head-left">
              {inputValues?.user_info_data?.user_persistent_id && (
                <div className="reports-head-left-block">
                  <span>Persistent ID:</span>
                  <b>{inputValues?.user_info_data?.user_persistent_id}</b>
                </div>
              )}

              {inputValues?.user_info_data?.user_name && (
                <div className="reports-head-left-block">
                  <span>Display Name:</span>
                  <b>{inputValues?.user_info_data?.user_name}</b>
                </div>
              )}
              {inputValues?.user_info_data?.description && (
                <div className="reports-head-left-block">
                  <span>About:</span>
                  <p>{inputValues?.user_info_data?.description}</p>
                </div>
              )}
              {inputValues?.user_info_data?.user_address && (
                <div className="reports-head-left-block">
                  <span>Blockchain Address:</span>
                  <b>{inputValues?.user_info_data?.user_address}</b>
                </div>
              )}
              <div className="reports-head-left-block">
                <span>Current Identity Verified?</span>

                {inputValues?.user_info_data?.is_verified ? (
                  <>
                    {" "}
                    <img
                      src={`${staticBasePath}public/images/svg/verified.svg`}
                      alt="verified"
                      className="rb-verify-img"
                    />
                    <b>Verified </b>
                  </>
                ) : (
                  <>
                    {" "}
                    <b>Not Verified </b>
                  </>
                )}
              </div>
            </div>
            <div className="reports-head-right">
              <div className="reports-blocks rb-text-center">
                {inputValues?.user_info_data?.total_stamps > 0 && (
                  <ReportBlock
                    Background="#EDF0E5"
                    Color="#000000"
                    Text="Total Stamps"
                    Number={inputValues?.user_info_data?.total_stamps}
                    position="top-right"
                    text=""
                    link=""
                  />
                )}
                {inputValues?.user_info_data?.total_collections > 0 &&
                  !collectionExists && (
                    <ReportBlock
                      Background="#1B2F28"
                      Color="#FFFFFF"
                      Text="Collections"
                      Number={inputValues?.user_info_data?.total_collections}
                      position="top-right"
                      text=""
                      link=""
                    />
                  )}
              </div>
            </div>
          </div>
          {collectionExists ? (
            <>
              <div className="html-table-wrap html-table-wrap-stamp-history">
                {filteredItems.length > 0 ? (
                  <div className="html-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Content ID</th>
                          <th>Timestamp</th>
                          <th>Receipt Links</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredItems.map((stamp_history_item, index) => (
                          <tr key={index}>
                            <td>
                              {stamp_history_item?.objectCid
                                ? formatHash(
                                    stamp_history_item?.objectCid,
                                    6,
                                    4,
                                    13,
                                  )
                                : "-"}
                              <img
                                className="html-table-copy"
                                src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                alt="copy"
                                onClick={() =>
                                  handleClickToCopy(
                                    stamp_history_item?.objectCid,
                                  )
                                }
                              />
                            </td>
                            <td>
                              {formatTimeStamp(
                                stamp_history_item?.timestamp,
                                userTimezone,
                                LONG_DISPLAY_FORMAT,
                              )}
                            </td>
                            <td>
                              {stamp_history_item?.objectCid && (
                                <a
                                  title="vBase Stamp Verification"
                                  href={`/verify/?cid=${stamp_history_item?.objectCid}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LogoVBSvg />
                                </a>
                              )}
                              {stamp_history_item?.transactionHash && (
                                <a
                                  title="Public Blockchain Record"
                                  href={`${stamp_history_item?.blockExplorerUrl}${stamp_history_item?.transactionHash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <LogoBlockchainSvg />
                                </a>
                              )}

                              <DownloadPDFUserFound
                                user_id={stamp_history_item.user_id}
                                user={stamp_history_item.user}
                                collection_name={
                                  stamp_history_item.collection_name
                                }
                                collection_hash={
                                  stamp_history_item.collection_hash
                                }
                                objectCid={stamp_history_item.objectCid}
                                blockExplorerUrl={
                                  stamp_history_item.blockExplorerUrl
                                }
                                transactionHash={
                                  stamp_history_item.transactionHash
                                }
                                chainId={stamp_history_item.chainId}
                                blockchainName={
                                  stamp_history_item.blockchainName
                                }
                                formattedDateTime={
                                  formatTimeStamp(
                                    stamp_history_item?.timestamp,
                                    userTimezone,
                                    LONG_DISPLAY_FORMAT,
                                  ) +
                                  " " +
                                  userTimezone
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="no-data">No Stamps Found.</p>
                )}
              </div>
              <FrontendPagination
                items={inputValues["commitment_receipts_data"]}
                itemsPerPage="20"
                onFilteredItemsChange={handleFilteredItemsChange}
                onFilteredItemsNoPaginationChange={
                  handleFilteredItemsNoPaginationChange
                }
                searchTerm={searchTerm}
                onCurrentPage={handleCurrentPage}
                currentPage={currentPage}
              />
            </>
          ) : (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              className="mantine-Tabs"
            >
              <div className="reports-table-head rb-display-flex rb-justify-content-space-between">
                <div className="reports-table-head-left">
                  <Tabs.List>
                    <Tabs.Tab value="collections">
                      <div className="reports-table-head-title">
                        Collections
                      </div>
                    </Tabs.Tab>
                    <Tabs.Tab value="stamp_history">
                      <div className="reports-table-head-title">
                        Stamp History
                      </div>
                    </Tabs.Tab>
                  </Tabs.List>
                </div>

                <div className="reports-table-head-right rb-display-flex rb-align-items-center">
                  {activeTab === "stamp_history" &&
                    filteredItemsNoPagination.length > 0 && (
                      <DownloadCSV
                        data={filteredItemsNoPagination}
                        displayName={inputValues?.user_info_data?.user_name}
                        userAddress={inputValues?.user_info_data?.user_address}
                      />
                    )}
                  {activeTab === "stamp_history" && selectOptions.length > 0 && (
                    <Select
                      label=""
                      placeholder="Filter by Collection"
                      data={selectOptions}
                      rightSection={selectIcon}
                      filter={(data) =>
                        optionsFilter({
                          options: data.options,
                          search: data.search,
                        })
                      }
                      searchable
                      value={searchTerm}
                      onChange={(value) => handleSelectChange(value)}
                      onSearchChange={(value) => handleSearchChange(value)}
                    />
                  )}
                </div>
              </div>

              <Tabs.Panel value="collections">
                <div className="html-table-wrap html-table-wrap-user-found">
                  {filteredItemsCollections.length > 0 ? (
                    <div className="html-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Collection Name</th>
                            <th>Collection ID</th>
                            <th># of Stamps</th>
                            <th>First Stamp</th>
                            <th>Last Stamp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredItemsCollections.map(
                            (collection_data, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() =>
                                    handleClickFilterredbyCollection(
                                      formatCollectionUserFound(
                                        collection_data.collection_name &&
                                          collection_data.collection_name !==
                                            "n/a"
                                          ? collection_data.collection_name
                                          : collection_data.collection_hash &&
                                            collection_data.collection_hash !==
                                              "n/a"
                                          ? formatHash(
                                              collection_data.collection_hash,
                                              6,
                                              4,
                                              13,
                                            )
                                          : "<<None>>",
                                      ).formatted,
                                    )
                                  }
                                >
                                  {
                                    formatCollectionUserFound(
                                      collection_data.collection_name &&
                                        collection_data.collection_name !==
                                          "n/a"
                                        ? collection_data.collection_name
                                        : collection_data.collection_hash &&
                                          collection_data.collection_hash !==
                                            "n/a"
                                        ? "<<Not Available>>"
                                        : "<<None>>",
                                    ).formatted
                                  }
                                  {collection_data.collection_hash === "n/a" ? (
                                    ""
                                  ) : collection_data.collection_name ? (
                                    <img
                                      className="html-table-copy"
                                      src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                      alt="copy"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickToCopy(
                                          collection_data.collection_name,
                                        );
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td
                                  onClick={() =>
                                    handleClickFilterredbyCollection(
                                      formatCollectionUserFound(
                                        collection_data.collection_hash &&
                                          collection_data.collection_hash !==
                                            "n/a"
                                          ? collection_data.collection_name !==
                                              "n/a" &&
                                            collection_data.collection_name
                                            ? formatCollectionUserFound(
                                                collection_data.collection_name,
                                              ).formatted
                                            : formatHash(
                                                collection_data.collection_hash,
                                                6,
                                                4,
                                                13,
                                              )
                                          : "<<None>>",
                                      ).formatted,
                                    )
                                  }
                                >
                                  {collection_data.collection_hash !== "n/a" ? (
                                    <>
                                      {formatHash(
                                        collection_data.collection_hash,
                                        6,
                                        4,
                                        13,
                                      )}
                                      <img
                                        className="html-table-copy"
                                        src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                        alt="copy"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleClickToCopy(
                                            collection_data.collection_hash,
                                          );
                                        }}
                                      />
                                    </>
                                  ) : (
                                    "<<None>>"
                                  )}
                                </td>
                                <td>{collection_data.count}</td>
                                <td>
                                  {formatTimeStamp(
                                    collection_data.first_timestamp,
                                    userTimezone,
                                    SHORT_DISPLAY_FORMAT,
                                  )}
                                </td>
                                <td>
                                  {formatTimeStamp(
                                    collection_data.last_timestamp,
                                    userTimezone,
                                    SHORT_DISPLAY_FORMAT,
                                  )}
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="no-data">
                      This user exists but has made no collections.
                    </p>
                  )}
                </div>
                {inputValues["collection_data"] &&
                inputValues["collection_data"].length > 0 ? (
                  <FrontendPaginationCollections
                    items={inputValues["collection_data"]}
                    itemsPerPage="20"
                    onFilteredItemsCollectionsChange={
                      handleFilteredItemsCollectionsChange
                    }
                    onCurrentPage={handleCurrentPage}
                    currentPage={currentPage}
                  />
                ) : null}
              </Tabs.Panel>

              <Tabs.Panel value="stamp_history">
                <div className="html-table-wrap html-table-wrap-stamp-history">
                  {filteredItems.length > 0 ? (
                    <div className="html-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Content ID</th>
                            <th>Timestamp</th>
                            <th>Receipt Links</th>
                            <th>Collection Name</th>
                            <th>Collection ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredItems.map((stamp_history_item, index) => (
                            <tr key={index}>
                              <td>
                                {stamp_history_item?.objectCid
                                  ? formatHash(
                                      stamp_history_item?.objectCid,
                                      6,
                                      4,
                                      13,
                                    )
                                  : "-"}
                                <img
                                  className="html-table-copy"
                                  src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                  alt="copy"
                                  onClick={() =>
                                    handleClickToCopy(
                                      stamp_history_item?.objectCid,
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {formatTimeStamp(
                                  stamp_history_item?.timestamp,
                                  userTimezone,
                                  LONG_DISPLAY_FORMAT,
                                )}
                              </td>
                              <td>
                                {stamp_history_item?.objectCid && (
                                  <a
                                    title="vBase Stamp Verification"
                                    href={`/verify/?cid=${stamp_history_item?.objectCid}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <LogoVBSvg />
                                  </a>
                                )}
                                {stamp_history_item?.transactionHash && (
                                  <a
                                    title="Public Blockchain Record"
                                    href={`${stamp_history_item?.blockExplorerUrl}${stamp_history_item?.transactionHash}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <LogoBlockchainSvg />
                                  </a>
                                )}

                                <DownloadPDFUserFound
                                  user_id={stamp_history_item.user_id}
                                  user={stamp_history_item.user}
                                  collection_name={
                                    stamp_history_item.collection_name
                                  }
                                  collection_hash={
                                    stamp_history_item.collection_hash
                                  }
                                  objectCid={stamp_history_item.objectCid}
                                  blockExplorerUrl={
                                    stamp_history_item.blockExplorerUrl
                                  }
                                  transactionHash={
                                    stamp_history_item.transactionHash
                                  }
                                  chainId={stamp_history_item.chainId}
                                  blockchainName={
                                    stamp_history_item.blockchainName
                                  }
                                  formattedDateTime={
                                    formatTimeStamp(
                                      stamp_history_item?.timestamp,
                                      userTimezone,
                                      LONG_DISPLAY_FORMAT,
                                    ) +
                                    " " +
                                    userTimezone
                                  }
                                />
                              </td>
                              <td
                                title={
                                  formatCollectionUserFound(
                                    stamp_history_item?.collection_name,
                                  ).title_hover &&
                                  stamp_history_item?.collection_name
                                    ? stamp_history_item?.collection_name
                                    : ""
                                }
                                onClick={() =>
                                  handleClickFilterredbyCollection(
                                    formatCollectionUserFound(
                                      stamp_history_item.collection_name &&
                                        stamp_history_item.collection_name !==
                                          "n/a"
                                        ? stamp_history_item.collection_name
                                        : stamp_history_item.collection_hash &&
                                          stamp_history_item.collection_hash !==
                                            "n/a"
                                        ? formatHash(
                                            stamp_history_item.collection_hash,
                                            6,
                                            4,
                                            13,
                                          )
                                        : "<<None>>",
                                    ).formatted,
                                  )
                                }
                              >
                                {stamp_history_item?.collection_name &&
                                stamp_history_item?.collection_name !==
                                  "n/a" ? (
                                  <>
                                    {
                                      formatCollectionUserFound(
                                        stamp_history_item?.collection_name,
                                      ).formatted
                                    }

                                    <img
                                      className="html-table-copy"
                                      src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                      alt="copy"
                                      onClick={() =>
                                        handleClickToCopy(
                                          stamp_history_item?.collection_name,
                                        )
                                      }
                                    />
                                  </>
                                ) : stamp_history_item?.collection_hash &&
                                  stamp_history_item?.collection_hash !==
                                    "n/a" ? (
                                  "<<Not Available>>"
                                ) : (
                                  "<<None>>"
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  handleClickFilterredbyCollection(
                                    stamp_history_item.collection_hash !== "n/a"
                                      ? stamp_history_item.collection_name !==
                                          "n/a" &&
                                        stamp_history_item.collection_name
                                        ? formatCollectionUserFound(
                                            stamp_history_item.collection_name,
                                          ).formatted
                                        : formatHash(
                                            stamp_history_item.collection_hash,
                                            6,
                                            4,
                                            13,
                                          )
                                      : "<<None>>",
                                  )
                                }
                              >
                                {stamp_history_item?.collection_hash !==
                                  "n/a" &&
                                stamp_history_item?.collection_hash ? (
                                  <>
                                    {formatHash(
                                      stamp_history_item?.collection_hash,
                                      6,
                                      4,
                                      13,
                                    )}

                                    <img
                                      className="html-table-copy"
                                      src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                                      alt="copy"
                                      onClick={() =>
                                        handleClickToCopy(
                                          stamp_history_item?.collection_hash,
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  "<<None>>"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="no-data">No Stamps Found.</p>
                  )}
                </div>
                <FrontendPagination
                  items={inputValues["commitment_receipts_data"]}
                  itemsPerPage="20"
                  onFilteredItemsChange={handleFilteredItemsChange}
                  onFilteredItemsNoPaginationChange={
                    handleFilteredItemsNoPaginationChange
                  }
                  searchTerm={searchTerm}
                  onCurrentPage={handleCurrentPage}
                  currentPage={currentPage}
                />
              </Tabs.Panel>
            </Tabs>
          )}
        </div>
      ) : null}
      {!collectionExists && (
        <div className="reports-buttons rb-display-flex rb-align-items-center">
          <a
            href={URLs.VERIFY_URL}
            className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
          >
            <LendingPricingSvg />
            Back to Verify
          </a>
        </div>
      )}
    </div>
  );
};

UserFoundContent.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
};
export default UserFoundContent;
