import PropTypes from "prop-types";
import React from "react";
import { handleClickToCopy } from "../utils/copyToClipboard";
import { formatHash } from "../utils/formatHash";

const StampNotFound = ({
  hash,
  staticBasePath,
  handleDefaultScreen,
  verificationMethod,
}) => {
  return (
    <>
      <img
        src={`${staticBasePath}public/images/svg/vb-icon-not-verified.svg`}
        alt="Red checkmark"
        className="rb-result-img align-center"
      />
      <h2 className="rb-header rb-header-stamp rb-text-center">
        No Stamp Found!
      </h2>
      <div
        id="stamp-not-found"
        className="rb-container rb-stamp-window rb-column rb-align-center"
      >
        <div className="scalloped-top"></div>
        <div className="scalloped-bottom"></div>
        <div className="scalloped-left"></div>
        <div className="scalloped-right"></div>
        <div className="rb-details-table rb-column rb-mt-xl rb-grow-0">
          <div className="rb-details-table__header">Technical Details</div>
          <div className="rb-details-table__item">
            <div className="rb-details-table__item__header">Content ID</div>
            <div className="rb-details-table__dotted-space">
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div
              id="stamp-not-found__content-id"
              className="rb-details-table__item__value"
            >
              {formatHash(hash)}
            </div>
            <img
              className="rb-details-table__copy"
              src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
              alt="copy"
              onClick={() => handleClickToCopy(hash)}
            />
          </div>
          {verificationMethod !== "hash" && (
            <div className="rb-details-table-not-found-text">
              ContentID verified using SHA3-256 and Keccak-256 algorithms.
            </div>
          )}
        </div>
      </div>
      <div className="rb-container">
        <button
          className="rb-btn rb-btn-click rb-btn-stamp-window rb-display-flex rb-justify-center"
          onClick={handleDefaultScreen}
        >
          Verify Another Stamp
        </button>
      </div>
    </>
  );
};

StampNotFound.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  verificationMethod: PropTypes.string.isRequired,
  handleDefaultScreen: PropTypes.func,
};

export default StampNotFound;
