import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Flex,
  Box,
  useMantineTheme,
  Title,
  Text,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  billingHistoryTableColumns,
  billingHistoryTableData,
  billingHistoryTableOptions,
} from "../../utils/UserProfile/billingHistoryTable";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useTableIcons } from "../../utils/collectionFound/iconsUtil";
import { IconChevronLeft } from "@tabler/icons-react";

const BillingHistory = ({ setBillingHistoryShow, timeZone }) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const [blackButtonIsHovered, setBlackButtonIsHovered] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // Loading state

  const handleClickReturnBilling = () => {
    setBillingHistoryShow(false);
  };
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    transactions: [],
    current_page: 1,
    total_pages: 1,
  });

  // Fetch user data from API

  const paddleData = async (page = 1) => {
    try {
      const response = await fetch(`/paddle/billing-history/?page=${page}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setInputValues({
        transactions: data?.transactions || [],
        current_page: data?.current_page || 1,
        total_pages: data?.total_pages || 1,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    paddleData(1);
  }, []);

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  const dataBillingHistory = useMemo(
    () => billingHistoryTableData(inputValues["transactions"], theme, timeZone),
    [inputValues["transactions"], theme, timeZone],
  );

  // Column definitions for the main table using a utility function
  const columnsBillingHistory = billingHistoryTableColumns(timeZone);

  // Configuration options for the main table using Mantine's table library
  const tableBillingHistory = useMantineReactTable(
    billingHistoryTableOptions(
      columnsBillingHistory,
      dataBillingHistory,
      faIcons,
      theme,
    ),
  );

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }
  return (
    <Box
      style={() => ({
        paddingTop: "5rem",
        paddingBottom: "6rem",
      })}
    >
      <Button
        type="button"
        variant="transparent"
        color={theme.customColors.black}
        styles={() => ({
          root: {
            fontSize: "1.15em",
            textDecoration: "underline",
            paddingLeft: "0",
            cursor: "pointer",
            overflow: "unset",
            marginBottom: "2em",
            opacity: "0.6",
            minWidth: 183,
          },
          inner: {
            display: "flex",
            justifyContent: "flex-start",
          },
        })}
        onClick={() => handleClickReturnBilling()}
      >
        Return to Billing
      </Button>

      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <Title
          order={2}
          styles={(theme) => ({
            root: {
              fontSize: "1.5em",
              fontFamily: theme.headings.fontFamily.secondary,
            },
          })}
        >
          Transactions
        </Title>
      </Flex>

      {/* List Transactions */}
      {inputValues["transactions"].length > 0 ? (
        <MantineReactTable mb="lg" table={tableBillingHistory} />
      ) : (
        <Text
          styles={() => ({
            root: {
              paddingLeft: "1.5rem",
            },
          })}
        >
          No transactions available.
        </Text>
      )}

      <Button
        styles={() => ({
          root: {
            fontSize: "1.1em",
            border: "1px solid " + theme.customColors.black,
            borderRadius: "0.3em",
            padding: "0.5em 1em",
            marginTop: "1.2em",
            color: blackButtonIsHovered
              ? theme.customColors.black
              : theme.customColors.white,
            backgroundColor: blackButtonIsHovered
              ? theme.customColors.white
              : theme.customColors.black,
          },
        })}
        onMouseEnter={() => {
          setBlackButtonIsHovered(true);
        }}
        onMouseLeave={() => {
          setBlackButtonIsHovered(false);
        }}
        onClick={() => handleClickReturnBilling()}
      >
        <IconChevronLeft
          style={{
            width: rem(21),
            height: rem(33),
            stroke: blackButtonIsHovered
              ? theme.customColors.black
              : theme.customColors.white,
          }}
        />
        Back
      </Button>
    </Box>
  );
};

BillingHistory.propTypes = {
  setBillingHistoryShow: PropTypes.func.isRequired, // If this prop is mandatory
  timeZone: PropTypes.string.isRequired,
};

export default BillingHistory;
