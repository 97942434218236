import PropTypes from "prop-types";
import React from "react";
import { Anchor, useMantineTheme } from "@mantine/core";
import { useStylesButton } from "../../utils/mantine/useStylesButton";
import { IconChevronLeft } from "@tabler/icons-react";

const CustomAnchor = ({
  text,
  componentClasses,
  href = "#",
  underline = "never",
  IconChevronLeftEnable = false,
  color = "",
}) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();
  if (!color) {
    color = theme.customColors.blackLight;
  }

  const { classes } = useStylesButton();
  const classList = componentClasses.split(" ");

  const combinedClasses = classList
    .map((className) => {
      return classes[className] || className;
    })
    .filter(Boolean);
  return (
    <Anchor
      href={href}
      c={color}
      underline={underline}
      className={combinedClasses.join(" ")}
    >
      {IconChevronLeftEnable ? (
        <IconChevronLeft className={classes.iconChevronLeft} />
      ) : (
        ""
      )}
      {text}
    </Anchor>
  );
};

CustomAnchor.propTypes = {
  text: PropTypes.string.isRequired,
  componentClasses: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  underline: PropTypes.bool,
  IconChevronLeftEnable: PropTypes.bool,
};

export default CustomAnchor;
