export const formatHash = (
  value,
  left = 10,
  right = 8,
  length = 21,
  removeDotted = false,
) => {
  if (value.length > length) {
    return removeDotted
      ? `${value.slice(0, left)}${value.slice(-right)}`
      : `${value.slice(0, left)}...${value.slice(-right)}`;
  }
  return value;
};
